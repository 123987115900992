import $ from '../../node_modules/jquery';
import AOS from '../../node_modules/aos';
import Alpine from 'alpinejs'
import Swiper from '../../node_modules/swiper/swiper-bundle.esm';


// init page
window.jQuery = $;
window.$ = $;

console.log('init page');

window.Alpine = Alpine

Alpine.start()

AOS.init();


// load header and footer
$(function(){
    $("#header").load("header.html"); 
    $("#footer").load("footer.html"); 
});


// swiper
var swiper2 = new Swiper(".mySwiper2", {
    slidesPerView: 3,
    spaceBetween: 30,
    slidesPerGroup: 1,
    grabCursor: true,
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
      clickable: true,
    },
    breakpoints: {
        "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        "@0.75": {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        "@1.00": {
            slidesPerView: 3,
            spaceBetween: 40,
        },
        "@1.50": {
            slidesPerView: 3,
            spaceBetween: 50,
        },
    },
});
var swiper2 = new Swiper(".mySwiper3", {
    slidesPerView: 1,
    spaceBetween: 30,
    slidesPerGroup: 1,
    grabCursor: true,
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
      clickable: true,
    },
    breakpoints: {
        "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        "@0.75": {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        "@1.00": {
            slidesPerView: 1,
            spaceBetween: 40,
        },
        "@1.50": {
            slidesPerView: 1,
            spaceBetween: 50,
        },
    },
});


// block scroll
$( "#hamburger" ).click(function() {
    $('body').toggleClass('block-scroll');
});
$( ".menu_item" ).click(function() {
    $('body').removeClass('block-scroll');
});


// scroll to top
setTimeout(function() { 
    var btn = $('#button');
    
    $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
            btn.addClass('show');
        } else {
            btn.removeClass('show');
        }
    });
    
    btn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, '300');
    });
}, 100);


// play video
$('#play-video').on('click', function(e){
    e.preventDefault();
    $('#video-overlay').addClass('open');
    $("#video-overlay").append('<iframe class="h-full w-full lg:w-1/2 lg:h-1/2" style="z-index:100" src="assets/images/video/video.mp4" frameborder="0" allowfullscreen></iframe>');
});
$('.video-overlay, .video-overlay-close').on('click', function(e){
    e.preventDefault();
    close_video();
});
$(document).keyup(function(e){
    if(e.keyCode === 27) { close_video(); }
});
function close_video() {
    $('.video-overlay.open').removeClass('open').find('iframe').remove();
};
